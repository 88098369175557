import { env } from './../../../environments/environment';
import { CoreUtilService } from '../core-util/core-util.service';
import { StateDataService } from './../state-data/state-data.service';
import { SocketService } from './../socket/socket.service';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StateService } from '@uirouter/core';
import { fromEvent } from 'rxjs';
import { ProductValue } from '../../app.types';

// eslint-disable-next-line no-var
declare var Base64: any;

type IntegrationRedirect = {
  uri: string,
  state: string,
  system: string,
  company_code: string,
  refresh_token: string,
  verification_token: string
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _timeout = {
    period: 60 * 60 * 1000,
    timer: null
  };

  private _session_key: string;
  private _two_factor_token: string;
  private _external_session_key: string;
  private _external_company_key: string;
  private _external_user_access_company_key: number;
  private _integration_redirect: IntegrationRedirect;
  private _integration_product: ProductValue;
  private _user_access_key: number; // used for droppah multi-auth

  private trace: string = CoreUtilService.generateUUID();

  public static readonly fallbackAuthError: string = 'Looks like we encountered an issue. '
    + 'If this continues to happen please contact support.';

  constructor(
    public http: HttpClient,
    public stateDataService: StateDataService,
    public socketService: SocketService,
    public stateService: StateService
  ) {
    this.session_key = this.stateDataService.getCachedComponentSessionData('AuthService', 'session_key');
    this._two_factor_token = this.stateDataService.getCachedComponentSessionData('AuthService', '2fa_token');
    this._integration_redirect = this.stateDataService.getCachedComponentSessionData('AuthService', 'integration_redirect');
    this._integration_product = this.stateDataService.getCachedComponentSessionData('AuthService', 'integration_product');

    // Update inactivity timer on page click
    fromEvent(document, 'click').subscribe(() => {
      this.updateTimeout();
    });
  }

  get session_key(): string {
    return this._session_key || null;
  }

  set session_key(session_key: string) {
    this._session_key = session_key;
    this.socketService.updateSocketSessionKey(!!session_key ? Base64.encode(session_key) : null);
    this.stateDataService.cacheComponentSessionData('AuthService', 'session_key', this._session_key);
  }

  get two_factor_token(): string {
    return this._two_factor_token || null;
  }

  set two_factor_token(two_factor_token: string) {
    this._two_factor_token = two_factor_token;
    this.stateDataService.cacheComponentSessionData('AuthService', '2fa_token', this._two_factor_token);
  }

  get external_session_key(): string {
    return this._external_session_key || null;
  }

  set external_session_key(external_session_key: string) {
    this._external_session_key = external_session_key;
  }

  get external_company_key(): string {
    return this._external_company_key || null;
  }

  set external_company_key(external_company_key: string) {
    this._external_company_key = external_company_key;
  }

  get external_user_access_company_key(): number {
    return this._external_user_access_company_key || null;
  }

  set external_user_access_company_key(external_user_access_company_key: number) {
    this._external_user_access_company_key = external_user_access_company_key;
  }

  get integration_redirect(): IntegrationRedirect {
    return this._integration_redirect;
  }

  set integration_redirect(redirect: IntegrationRedirect) {
    this._integration_redirect = redirect;
    this.stateDataService.cacheComponentSessionData('AuthService', 'integration_redirect', this._integration_redirect);
  }

  get integration_product(): ProductValue {
    return this._integration_product;
  }

  set integration_product(product: ProductValue) {
    this._integration_product = product;
    this.stateDataService.cacheComponentSessionData('AuthService', 'integration_product', this._integration_product);
  }

  get integration_redirect_flag(): boolean {
    return !!this._integration_redirect;
  }

  set user_access_key(user_access_key: number){
    this._user_access_key = user_access_key;
  }

  get user_access_key(): number {
    return this._user_access_key;
  }

  login(username: string, password: string, login_token: string, two_factor_token: string) {
    return new Promise<any>((resolve, reject) => {

      const credentials = {
        username,
        password,
        login_token,
        login_source: env.login_source,
        two_factor_token
      };

      this.APIAuthPost('user_access/login', credentials)
        .then((data) => {
          if (data?.user_access_key) {
            this.stateDataService.intialiseLocalStorage(data.user_access_key);
            this.stateDataService.cacheLocalData('intercom_hash', data.intercom_hash);
            this.stateDataService.cacheLocalData('droppah_intercom_hash', data.droppah_intercom_hash);
            this.stateDataService.cacheLocalData('karmly_intercom_hash', data.karmly_intercom_hash);
          }
          if (data?.session_key) {
            this.session_key = data.session_key;
            this.two_factor_token = two_factor_token;

            this.stateDataService.cacheGlobalLocalData('username', username);

            resolve(data);
          }
          else if (data?.two_factor_required) {
            resolve(data);
          }
          else {
            reject({
              message: AuthService.fallbackAuthError,
              data: null
            });
          }
        })
        .catch((err) => reject(err))
        .finally(() => this.updateTimeout());
    });
  }

  logout(error_message = null) {
    this.session_key = null;
    this.two_factor_token = null;
    this.stateDataService.clearAllSessionData();

    this.stateService.go('login', { error_message });
  }

  initialiseTwoFactor(verification_token: string) {
    return new Promise<any>((resolve, reject) => {

      const credentials = {
        token_uid: verification_token
      };

      this.APIAuthPost('user_access/login/init2fa', credentials)
        .then((data) => {
          if (data && data.qr_uri) {
            resolve(data);
          }
          else {
            reject({
              message: AuthService.fallbackAuthError,
              data: null
            });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  verifyAuth(): boolean {
    return !!this._session_key;
  }

  verifyTwoFactor(verification_token: string, two_factor_code: string, remember_me_flag: boolean) {
    return new Promise<any>((resolve, reject) => {

      const credentials = {
        token_uid: verification_token,
        code: two_factor_code,
        remember_me_flag
      };

      this.APIAuthPost('user_access/login/verify2fa', credentials)
        .then((data) => {
          if (data && data.two_factor_token) {
            this._two_factor_token = data.two_factor_token;
            if (remember_me_flag) {
              this.cacheTwoFactor();
            }
            resolve(data);
          }
          else {
            reject({
              message: 'Invalid code. Please retry.',
              data: null
            });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateTimeout() {
    if (this._session_key) {

      if (this._timeout.timer) {
        clearTimeout(this._timeout.timer);
      }

      this._timeout.timer = setTimeout(() => {
        if (this.verifyAuth()) {
          this.logout('You\'ve been logged out due to inactivity');
        }
      }, this._timeout.period);
    }
  }

  /////////////////////////////////////////
  getCachedUsername() {
    return this.stateDataService.getCachedGlobalLocalData('username');
  }

  cacheTwoFactor() {
    if (!this._two_factor_token) { return; }

    const expiry = new Date();
    expiry.setDate(expiry.getDate() + 30);

    const two_factor = {
      token: this._two_factor_token,
      expiry_date: expiry.valueOf()
    };

    let two_factor_string = CoreUtilService.stringifyJSON(two_factor);
    two_factor_string = btoa(two_factor_string);

    this.stateDataService.cacheGlobalLocalData('2fa_token', two_factor_string);
  }

  getCachedTwoFactor(): string {
    let two_factor_string = this.stateDataService.getCachedGlobalLocalData('2fa_token');
    if (!two_factor_string) { return null; }

    two_factor_string = atob(two_factor_string);
    const two_factor = CoreUtilService.parseJSON(two_factor_string);
    if (!two_factor) { return null; }

    if (two_factor.expiry_date <= new Date().valueOf()) {
      this.stateDataService.clearCachedGlobalLocalData('2fa_token');
      return null;
    }
    else {
      return two_factor.token;
    }
  }

  /////////////////////////////////////////

  getHTTPHeader(): any {
    if (this._session_key) {
      return {
        Authorization: 'Basic ' + Base64.encode(this._session_key),
        trace: this.trace
      };
    }
    else {
      return null;
    }
  }

  getProductHTTPHeader(product_session): any {
    if (product_session && product_session.session_key && product_session.external_company_reference) {
      return {
        Authorization: 'Basic ' + Base64.encode(product_session.external_company_reference + ':' + product_session.session_key),
        trace: this.trace
      };
    }
    else {
      return null;
    }
  }

  APIAuthPost(URL: string, creds: any) {
    return new Promise<any>((resolve, reject) => {

      this.http
        .post(env.subscription.api_url + URL, creds)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(this.processAPIError(err));
          }
        );
    });
  }

  APIAuthGet(URL: string, params: any, responseType: string = 'json') {
    return new Promise<any>((resolve, reject) => {
      const options: any = {
        params,
        responseType
      };

      this.http
        .get(env.subscription.api_url + URL, options)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            console.log(err);
            reject(this.processAPIError(err));
          }
        );
    });
  }

  processAPIError(err: any): any {
    if (err && err.error && typeof err.error === 'string') {
      return {
        message: err.error,
        data: err
      };
    }
    else {
      return {
        message: AuthService.fallbackAuthError,
        data: err
      };
    }
  }

}
