import { BankEditStep, Country, FormInputField, PaymentMethod } from './../../app.types';
import { Account } from 'src/app/models/account/account';
import { Component, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FTSubscription } from 'src/app/models/ft-subscription/ft-subscription';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { Subscription } from 'rxjs';
import { SubscriptionPlan } from 'src/app/models/subscription-plan/subscription-plan';
import { AccountService } from 'src/app/services/account/account.service';
import { UserService } from 'src/app/services/user/user.service';
import { ProductService } from 'src/app/services/product/product.service';
import * as moment from 'moment';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';
import { SortUtilService } from 'src/app/services/sort-util/sort-util.service';
import { BankEditComponent } from 'src/app/components/bank-edit/bank-edit.component';
import { RedirectService } from 'src/app/services/redirect/redirect.service';
import { StateAccessService } from 'src/app/services/state-access/state-access.service';

type SubscriptionStep = (
  'PLAN' |
  'DETAILS' |
  'PAYMENT' |
  'REVIEW'
);

type PromoCodeStatus = (
  'INVALID' |
  'EXPIRED' |
  'VALID' |
  ''
);

@Component({
  selector: 'app-subscribe-modal',
  templateUrl: './subscribe-modal.component.html',
  styleUrls: ['./subscribe-modal.component.scss']
})
export class SubscribeModalComponent implements OnInit, OnDestroy {

  @Input() account: Account;
  @Input() pendingOwnershipTransfer: boolean;
  @Input() changingPlan: boolean;
  @Input() autoLoginCurrentAccount: boolean;

  @ViewChild('bankEdit') bankEdit: BankEditComponent;

  readonly is_partner: boolean = this.stateAccessService.isPartner;

  progressSteps: { key: SubscriptionStep, name: string, active: boolean }[];
  currentStep: SubscriptionStep;
  currentStepIndex: number;

  subscription: FTSubscription;
  backupSubscription: FTSubscription;

  countries: Country[] = this.userService.getCountries();

  isPartnerAdmin: boolean;
  reloadProductDetails: boolean;

  availablePlans: SubscriptionPlan[];
  currentPlanIndex: number;
  selectedPlanIndex: number;

  editingPromoCode: boolean;
  promoCode: string;
  promoCodeStatus: PromoCodeStatus;
  promoCodeDescription: string;
  autoPromoCode: string;

  selectedCountry: any;

  hasNoPaymentMethod: boolean;
  editingPaymentMethod: boolean;
  selectedPaymentMethod: PaymentMethod;

  currentDDStep: BankEditStep = 'FORM';

  bank_errors = {
    account_name: false,
    account_number: false,
    bank_name: false,
    bank_code: false
  };
  cardUpdateEventListener: Subscription = null;

  progressTrackWidth: number;
  subscriptionComplete: boolean;

  agreementAcknowledged: boolean;

  error_message: string;

  formFields: FormInputField[] = [];
  formFieldErrors: Record<string, string> = {};

  charges: any[] = [];

  loading: boolean;


  constructor(
    private activeModal: NgbActiveModal,
    private userService: UserService,
    private subscriptionService: SubscriptionService,
    private accountService: AccountService,
    private productService: ProductService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private stateAccessService: StateAccessService,
    private redirectService: RedirectService) { }

  ngOnInit(): void {
    this.promoCode = '';
    this.promoCodeStatus = '';
    this.promoCodeDescription = '';
    this.editingPromoCode = false;
    this.autoPromoCode = this.account.auto_promo_code;

    if (this.account.product_name === 'Invoxy') {
      this.account.product_name = 'Karmly';
    }

    this.subscriptionComplete = false;
    this.agreementAcknowledged = false;

    this.subscription = this.subscriptionService.getSubscription();
    this.backupSubscription = this.subscriptionService.getSubscription();
    this.selectedPaymentMethod = this.subscription.payment_method;

    this.isPartnerAdmin = this.userService.user_access.partner_flag && !this.userService.user_access.partner_owner_flag;
    this.reloadProductDetails = this.account.subscribed_flag && (this.pendingOwnershipTransfer || this.changingPlan);

    this.formFields = [
      { property: 'billing_name', label: 'Billing Name', field_required: true },
      { property: 'billing_email', label: 'Billing Email', field_type: 'email', field_required: true },
    ];


    this._initEventListeners();
    this.initProgressSteps();
    this.initSubscriptionOptions();
    this.initCharges();
    this.gotoStep(0);
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  private _initEventListeners() {
    this.cardUpdateEventListener = this.subscriptionService.getCardUpdateEvent().subscribe((event) => {
      if (event.success) {
        this.reloadPaymentMethod();
      }
      else {
        this.error_message = event.message;
        setTimeout(() => {
          this.error_message = '';
        }, 5000);
      }
    });
  }

  initProgressSteps() {
    this.progressSteps = [];

    this.progressSteps.push({ key: 'PLAN', name: 'Select Plan', active: true });

    if (!this.isPartnerAdmin) {
      this.progressSteps.push({ key: 'DETAILS', name: 'Billing Details', active: false });
    }

    if (!this.isPartnerAdmin && this.selectedPaymentMethod !== 'INVOICE') {
      this.progressSteps.push({ key: 'PAYMENT', name: 'Payment Method', active: false });
    }

    this.progressSteps.push({ key: 'REVIEW', name: 'Review', active: false });
  }

  initSubscriptionOptions() {
    this.loading = true;

    this.subscriptionService.getAvailableSubscriptionPlans(this.account)
      .then((availablePlans) => {
        this.availablePlans = availablePlans;
        this.selectedPlanIndex = this.availablePlans.length - 1;

        SortUtilService.sortList(this.availablePlans, 'unit_fee', null, false); // sort so the plans are displayed from least to most expensive per unit fee (in case plans are out of order in DB)

        // Show current plan when changing
        if (this.changingPlan) {
          for (let i = 0; i < this.availablePlans.length; i++) {
            if (this.availablePlans[i].subscription_plan_key === this.account.account_subscription.base_plan.subscription_plan_key) {
              this.currentPlanIndex = i;
              this.selectedPlanIndex = i;
            }
          }
        }
        // Check if we need to auto apply a promo code. Must meet the conditions (taken from html show conditions) to be able to enter a promo code
        else if (!this.changingPlan && !this.pendingOwnershipTransfer && !!this.autoPromoCode) {
          this.promoCode = this.autoPromoCode;
          this.validatePromoCode();
          // If the auto applied promo code is bad for whatever reason, unset the variable so they are returned to a normal state
          if (this.promoCodeStatus !== 'VALID') {
            this.promoCode = '';
            this.promoCodeStatus = '';
            this.autoPromoCode = '';
          }
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  initCharges() {
    this.charges = this.account.account_subscription.subscription_charges;

    this.charges.forEach((charge) => {
      if (charge.renewal_date) {
        charge.formatted_renewal_date = moment(charge.renewal_date).format('DD/MM/YYYY');
      }
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  gotoStep(stepIndex: number) {

    // Save billing details when going forward
    if (this.currentStep === 'DETAILS' && this.checkBillingDetailsChanged() && stepIndex > this.currentStepIndex) {
      this.formFieldErrors = {};

      const errors = this.subscription.getErrors();

      if (!errors.length) {
        this.saveBillingDetails();
      } else {
        for (const error of errors) {
          let error_matches_form_field = false;

          for (const form_field of this.formFields) {
            if (form_field.property === error.error_path) {
              this.formFieldErrors[error.error_path] = error.error_message;
              error_matches_form_field = true;
            }
          }
        }
        return;
      }
    }


    if (this.currentStep === 'PLAN' && !!this.availablePlans[this.selectedPlanIndex].unit_limit && this.account.account_subscription.unit_count > this.availablePlans[this.selectedPlanIndex].unit_limit) {
      let currentSelectedPlan = this.availablePlans[this.selectedPlanIndex];
      this.error_message = `${currentSelectedPlan.plan_display_name} is only available for accounts with up to ${currentSelectedPlan.unit_limit} ${currentSelectedPlan.unit_limit > 1 ? currentSelectedPlan.unit_description_plural.toLocaleLowerCase() : currentSelectedPlan.unit_description.toLocaleLowerCase()}.`;
      return;
    }

    this.error_message = '';
    this.editingPaymentMethod = false;
    this.agreementAcknowledged = false;
    this.currentDDStep = 'FORM';

    this.currentStepIndex = stepIndex;
    this.currentStep = this.progressSteps[this.currentStepIndex].key;
    this.progressTrackWidth = (100 / this.progressSteps.length) * (this.currentStepIndex + 1);

    // Update active steps
    for (let i = 0; i < this.progressSteps.length; i++) {
      if (stepIndex >= i) {
        this.progressSteps[i].active = true;
      }
      else {
        this.progressSteps[i].active = false;
      }
    }

    // Step specific actions
    if (this.subscription.has_no_payment_method && this.currentStep === 'PAYMENT') {
      this.selectCardPaymentMethod();
    }
  }

  goToDDStep(step: BankEditStep) {
    this.currentDDStep = step;
  }

  cancelPromoCode() {
    this.promoCode = '';
    this.promoCodeStatus = '';
    this.promoCodeDescription = '';
    this.editingPromoCode = false;
  }

  comparePlans() {
    const url = this.productService.getTheme(this.account.product_value, 'pricingURL');

    window.open(url, '_blank');
  }

  validatePromoCode() {
    const promoCodes = this.availablePlans[this.selectedPlanIndex].promo_codes;
    const code = this.promoCode.toUpperCase();

    this.promoCodeStatus = '';
    this.promoCodeDescription = '';

    if (!code) {
      return;
    }

    if (!promoCodes[code]) {
      this.promoCodeStatus = 'INVALID';
      return;
    }

    if (promoCodes[code].expired_flag) {
      this.promoCodeStatus = 'EXPIRED';
      return;
    }

    this.promoCodeStatus = 'VALID';
    this.promoCodeDescription = promoCodes[code].description;
  }

  saveBillingDetails() {
    this.loading = true;

    this.subscriptionService.saveBillingInformation(this.subscription)
      .then(() => {
        this.reloadPaymentMethod();
      })
      .catch(() => {
        this.loading = false;
      });
  }

  reloadPaymentMethod() {
    this.loading = true;

    this.subscriptionService.loadSubscription()
      .then(() => {
        this.subscription = this.subscriptionService.getSubscription();
        this.backupSubscription = this.subscriptionService.getSubscription();
        this.editingPaymentMethod = false;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  editCard() {
    this.editingPaymentMethod = true;
  }

  setErrorMessage(message: string) {
    this.error_message = message;
    setTimeout(() => {
      this.error_message = '';
    }, 5000);
  }

  authoriseDirectDebit() {
    this.bank_errors = this.bankEdit?.updateBankErrors();

    if (Object.values(this.bank_errors).includes(true)) {
      this.currentDDStep = 'FORM';
      return;
    }

    this.loading = true;
    this.subscriptionService.authoriseDirectDebit(this.subscription)
      .then(() => {
        this.currentDDStep = 'FORM';
        this.reloadPaymentMethod();
      })
      .catch(() => {
        this.loading = false;
      });
  }

  subscribe() {
    this.loading = true;
    this.validatePromoCode();
    this.accountService.subscribeAccount(this.account.company_product_key,
      this.selectedPaymentMethod,
      this.availablePlans[this.selectedPlanIndex].subscription_plan_key,
      this.promoCodeStatus === 'VALID' && !this.pendingOwnershipTransfer && !this.changingPlan ? this.promoCode.toUpperCase() : null,
      this.reloadProductDetails)
      .then(() => {
        this.googleAnalyticsService.trackEvent('subscribe', this.account.product_value.toLowerCase(), this.account.user_access_key, this.account.company_product_key);
        if(this.autoLoginCurrentAccount) {
          this.productService.startNewSession(this.account.company_product_key, this.account.product_value)
            .then((session) => {
              this.redirectService.redirectToProduct(session.login_source, this.is_partner, null);
            })
            .finally(() => { this.subscriptionComplete = true;});
        }
        else {
          this.subscriptionComplete = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  selectBillingCountry(country: Country) {
    this.subscription.country = country;
    this.selectedCountry = country;
  }

  selectCardPaymentMethod() {
    this.selectedPaymentMethod = 'CARD';
    this.editingPaymentMethod = false;
  }

  selectDirectDebitPaymentMethod() {
    this.selectedPaymentMethod = 'DIRECTDEBIT';
    this.editingPaymentMethod = true;
  }

  selectPlan(planIndex: number) {
    this.selectedPlanIndex = planIndex;
    this.error_message = '';
  }

  checkBillingDetailsChanged() {
    return (
      this.subscription.billing_name !== this.backupSubscription.billing_name ||
      this.subscription.billing_email !== this.backupSubscription.billing_email ||
      this.subscription.billing_address !== this.backupSubscription.billing_address ||
      this.subscription.country.country_key !== this.backupSubscription.country.country_key);
  }

  private _clearEventListeners() {
    this.cardUpdateEventListener?.unsubscribe();
  }
}
