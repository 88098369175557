import { PipesModule } from './../../pipes/pipes.module';


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

import { AppStateDeclaration } from './../../app.types';
import { ComponentsModule } from './../../components/components.module';

import { AccountComponent } from './account.component';
import { AccountDashComponent } from './account-dash/account-dash.component';
import { AccountCreateComponent } from './account-create/account-create.component';
import { AccountPartnerDashComponent } from './account-partner-dash/account-partner-dash.component';
import { AccountIntegrationComponent } from './account-integration/account-integration.component';

export const ACCOUNT_CONFIG: AppStateDeclaration[] = [
  {
    name: 'accountIntegration',
    url: '/accountIntegration',
    component: AccountIntegrationComponent,
    params: {
      integrated_companies: null
    }
  },
  {
    name: 'app.account',
    url: '/account',
    component: AccountComponent,
    abstract: true
  },
  {
    name: 'app.account.dash',
    url: '/dash',
    component: AccountDashComponent,
    default_state: true,
    params: {
      auto_select_account_flag: false,
      auto_select_account_product_value: null,
      check_for_expired_trial_account: false
    }
  },
  {
    name: 'app.account.partnerDash',
    url: '/partnerDash',
    component: AccountPartnerDashComponent
  },
  {
    name: 'app.account.create',
    url: '/create?company_name&product&marketing_additional_data',
    component: AccountCreateComponent,
    params: {
      company_name: null,
      product: null,
      marketing_additional_data: null,
      auto_account_create: false,
      company_product_key: null,
      product_session: null
    }
  }
];

@NgModule({
  declarations: [
    AccountComponent,
    AccountDashComponent,
    AccountCreateComponent,
    AccountPartnerDashComponent,
    AccountIntegrationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    NgxMaskModule,
    PipesModule,
    UIRouterModule.forChild({ states: ACCOUNT_CONFIG })
  ]
})
export class AccountModule { }
