import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ClientNote } from 'src/app/models/client-note/client-note';

import { ConfirmModalComponent } from './../../modals/confirm-modal/confirm-modal.component';
import { SuccessModalComponent } from './../../modals/success-modal/success-modal.component';
import { ErrorModalComponent } from './../../modals/error-modal/error-modal.component';
import { InitialiseTwoFactorModalComponent } from 'src/app/modals/initialise-two-factor-modal/initialise-two-factor-modal.component';
import { SubscribeModalComponent } from 'src/app/modals/subscribe-modal/subscribe-modal.component';
import { UpdatePaymentMethodModalComponent } from 'src/app/modals/update-payment-method-modal/update-payment-method-modal.component';
import { AddClientNoteModalComponent } from 'src/app/modals/add-client-note-modal/add-client-note-modal.component';
import { ManageStaffRoleModalComponent } from 'src/app/modals/manage-staff-role-modal/manage-staff-role-modal.component';
import { FormModalComponent } from 'src/app/modals/form-modal/form-modal.component';
import { AddAccountModalComponent } from './../../modals/add-account-modal/add-account-modal.component';
import { ProductValue, ConfirmModalType, ConfirmModalCustomData, FormInputField, UserAccessAgreements, PaymentMethod } from './../../app.types';
import { ManageClientStaffAccessModalComponent } from 'src/app/modals/manage-client-staff-access-modal/manage-client-staff-access-modal.component';
import { TransferOwnershipModalComponent } from './../../modals/transfer-ownership-modal/transfer-ownership-modal.component';
import { ChangePasswordModalComponent } from './../../modals/change-password-modal/change-password-modal.component';
import { UserAccessAgreementsModalComponent } from './../../modals/user-access-agreements-modal/user-access-agreements-modal.component';

import { Account } from 'src/app/models/account/account';
import { CancelAccountModalComponent } from 'src/app/modals/cancel-account-modal/cancel-account-modal.component';
import * as internal from 'assert';
import { PhEmployeeModalComponent } from '../../modals/ph-employee-modal/ph-employee-modal.component';
import { PhEmployee } from 'src/app/models/ph-employee/ph-employee';
import { PhImportModalComponent } from 'src/app/modals/ph-import-modal/ph-import-modal.component';
import { PhEmployeeInfoModalComponent } from 'src/app/modals/ph-employee-info-modal/ph-employee-info-modal.component';
import { PayInvoiceModalComponent } from 'src/app/modals/pay-invoice-modal/pay-invoice-modal.component';
import { SubscriptionInvoice } from 'src/app/models/subscription-invoice/subscription-invoice';
import { SubscriptionCard } from 'src/app/models/subscription-card/subscription-card';
import { UserAccess } from 'src/app/models/user-access/user-access';
import { UserAccessModalComponent } from 'src/app/modals/user-access-modal/user-access-modal.component';
import { cloneDeep } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  readonly adminMobileError: string =
    '<h4>The PayHero app is optimised for a larger screen size. ' +
    'Please adjust your display settings or try logging in from a device with a minimum display width of 768px.</h4>';

  constructor(
    private ngbModal: NgbModal
  ) {
    this._ensureOnlySingleErrorModalOpen();
  }

  async adminOwnerErrorModal() {
    this.errorModal(null, this.adminMobileError);
  }

  /**
   * Modal for showing success messages
   *
   * @param header
   * @param message
   * @param htmlMessage - can be used instead of normal message. Allows us to pass chunks of HTML with extra styling etc to use as a message
   */
  async successModal(header: string = null, message: string = null, htmlMessage: string = null) {
    const modal = this.ngbModal.open(SuccessModalComponent, { size: 'lg' });

    modal.componentInstance.header = header;
    modal.componentInstance.message = message;
    modal.componentInstance.htmlMessage = htmlMessage;

    return modal.result
      .then(() => {
        return null;
      })
      .catch(() => {
        return null;
      });
  }

  /**
   * Modal for showing error messages
   *
   * @param message
   * @param htmlMessage - can be used instead of normal message. Allows us to pass chunks of HTML with extra styling etc to use as a message
   */
  async errorModal(message: string = null, htmlMessage: string = null) {
    const modal = this.ngbModal.open(ErrorModalComponent, { size: 'lg' });

    modal.componentInstance.message = message;
    modal.componentInstance.htmlMessage = htmlMessage;

    return modal.result
      .then(() => {
        return null;
      })
      .catch(() => {
        return null;
      });
  }

  /**
   * Modal confirming changes/actions in PayHero - to make a new confirm modal create a new config in the controller.
   *
   * @param type
   * @param itemType - a string - (e.g "Leave Type")
   * @param itemTitle - a variable - (e.g vm.leave_type_name)
   * @param itemDescription - Additional description prepended to question - (e.g "This leave is the best leave you'll ever take.")
   * @param additionalWarning - Additional warning message appended to warning -  (e.g "Also, changing this is not a good idea!")
   * @param customData
   */
  async confirmModal(
    type: ConfirmModalType, itemType: string, itemTitle: string, itemDescription: string,
    additionalWarning: string = null, customData: ConfirmModalCustomData = null
  ) {
    return new Promise<any>((resolve, reject) => {
      const modal = this.ngbModal.open(ConfirmModalComponent, { size: 'lg' });

      modal.componentInstance.type = type;
      modal.componentInstance.itemType = itemType;
      modal.componentInstance.itemTitle = itemTitle;
      modal.componentInstance.itemDescription = itemDescription;
      modal.componentInstance.additionalWarning = additionalWarning;
      modal.componentInstance.customData = customData;

      return modal.result
        .then((result) => {
          resolve(result);
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Modal for initialising two factor authentication
   */
  async initialiseTwoFactorModal() {
    return new Promise<any>((resolve, reject) => {
      const modal = this.ngbModal.open(InitialiseTwoFactorModalComponent, { size: 'lg' });

      return modal.result
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Modal for subscribing
   */
  async subscribeModal(account: Account, auto_login_current_account: boolean = false) {
    return new Promise<any>((resolve, reject) => {
      const modal = this.ngbModal.open(SubscribeModalComponent, { size: 'lg' });

      modal.componentInstance.account = account;
      modal.componentInstance.pendingOwnershipTransfer = account.pending_owner_flag;
      modal.componentInstance.changingPlan = !account.pending_owner_flag && account.account_subscription.can_change_plan;
      modal.componentInstance.autoLoginCurrentAccount = auto_login_current_account;

      return modal.result
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async transferOwnershipModal(
    account: Account
  ): Promise<{
    account: Account,
    transfer_contact_name: string,
    transfer_email_address: string
  }> {
    return new Promise((resolve, reject) => {
      const modal = this.ngbModal.open(TransferOwnershipModalComponent, { size: 'lg' });

      modal.componentInstance.account = account;

      return modal.result
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Modal for subscribing
   */
  async updatePaymentMethodModal() {
    return new Promise<any>((resolve, reject) => {
      const modal = this.ngbModal.open(UpdatePaymentMethodModalComponent, { size: 'lg' });

      // modal.componentInstance.company = company;

      return modal.result
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Modal for adding account
   */
  async addAccountModal(
    demo_accounts_optional: boolean = false,
    demo_accounts_only: boolean = false,
    creating_partner_account: boolean = false
  ): Promise<{
    product_value: ProductValue,
    company_name: string,
    create_demo: boolean,
    region: string,
    number_of_employees: string,
    industry_key: number,
    business_type: string,
    nzbn: string,
    industry_classification: string,
    ird_number: string,
    sub_agreement_flag: boolean,
    contact_phone: string,
    country_province_key: string
  }> {
    return new Promise((resolve, reject) => {
      const modal = this.ngbModal.open(AddAccountModalComponent, { size: 'lg' });

      modal.componentInstance.demo_accounts_optional = demo_accounts_optional;
      modal.componentInstance.demo_accounts_only = demo_accounts_only;
      modal.componentInstance.creating_partner_account = creating_partner_account;

      return modal.result
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Modal for adding or viewing a note on a client
   */
  async addClientNoteModal(note: ClientNote = null, viewOnly: boolean = false) {
    return new Promise<any>((resolve, reject) => {
      const modal = this.ngbModal.open(AddClientNoteModalComponent);

      modal.componentInstance.note = note;
      modal.componentInstance.viewOnly = viewOnly;

      return modal.result
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Modal for adding or viewing a note on a client
   */
  async manageStaffRoleModal(full_name: string = null, admin_flag: boolean = false) {
    return new Promise<any>((resolve, reject) => {
      const modal = this.ngbModal.open(ManageStaffRoleModalComponent);

      modal.componentInstance.full_name = full_name;
      modal.componentInstance.admin_flag = admin_flag;

      return modal.result
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
  * Generic form modal
  */
  async formModal(title: string, object: any, formFields: FormInputField[]) {
    return new Promise<any>((resolve, reject) => {
      const modal = this.ngbModal.open(FormModalComponent);

      modal.componentInstance.title = title;
      modal.componentInstance.formFields = formFields;
      modal.componentInstance.object = object;

      return modal.result
        .then((object) => {
          resolve(object);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
  * Account Cancellation modal
  */
  async cancellationModal(account: Account, partner_flag: boolean) {
    return new Promise<any>((resolve, reject) => {
      const modal = this.ngbModal.open(CancelAccountModalComponent);

      modal.componentInstance.account = account;
      modal.componentInstance.isPartner = partner_flag;

      return modal.result
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
  * Modal for changing user password
  */
  async changePasswordModal(): Promise<{ old_password: string, new_password: string }> {
    return new Promise<{ old_password: string, new_password: string }>((resolve, reject) => {
      const modal = this.ngbModal.open(ChangePasswordModalComponent);

      return modal.result
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Modal for managing staff access to client accounts
   */
  async manageClientStaffAccessModal(client_key: number, partner_staff_key: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const modal = this.ngbModal.open(ManageClientStaffAccessModalComponent);

      modal.componentInstance.client_key = client_key;
      modal.componentInstance.partner_staff_key = partner_staff_key;

      return modal.result
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async userAccessAgreementsModal(user_access_agreements: UserAccessAgreements): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const modal = this.ngbModal.open(UserAccessAgreementsModalComponent);

      modal.componentInstance.user_access_agreements = user_access_agreements;

      return modal.result
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  phEmployeeInfoModal(): Promise<any> {
    return new Promise<PhEmployee>((resolve, reject) => {
      const modal = this.ngbModal.open(PhEmployeeInfoModalComponent, { size: 'lg', windowClass: 'ph-info-modal' });


      return modal.result
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Modal for adding an employee during payhero account creation
   */
  async phEmployeeModal(employee: PhEmployee = null): Promise<PhEmployee> {
    return new Promise<PhEmployee>((resolve, reject) => {
      const modal = this.ngbModal.open(PhEmployeeModalComponent, { size: 'lg' });

      modal.componentInstance.employee = cloneDeep(employee);

      return modal.result
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async phImportModal(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      const modal = this.ngbModal.open(PhImportModalComponent);

      return modal.result
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  async payInvoiceModal(suspended_invoices: SubscriptionInvoice[], subscription_card: SubscriptionCard, payment_method: PaymentMethod, email: String): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      const modal = this.ngbModal.open(PayInvoiceModalComponent);

      modal.componentInstance.overdue_invoices = cloneDeep(suspended_invoices);
      modal.componentInstance.subscription_card = cloneDeep(subscription_card);
      modal.componentInstance.payment_method = cloneDeep(payment_method);
      modal.componentInstance.email = cloneDeep(email);

      return modal.result
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  async userAccessModal(user_access: UserAccess): Promise<UserAccess> {
    return new Promise<UserAccess>((resolve, reject) => {
      const modal = this.ngbModal.open(UserAccessModalComponent);

      modal.componentInstance.user_access = user_access;

      return modal.result
        .then((res) => {
          resolve(res);
        })
        .catch(() => {
          reject();
        });
    });
  }

  private _ensureOnlySingleErrorModalOpen() {
    this.ngbModal.activeInstances.subscribe((modals: NgbModalRef[]) => {
      let index_of_last_error_modal = null;

      for (let i = 0; i < modals.length; i++) {
        if (modals[i].componentInstance instanceof ErrorModalComponent) {
          index_of_last_error_modal = i;
        }
      }

      if (index_of_last_error_modal !== null) {

        for (let i = index_of_last_error_modal - 1; i >= 0; i--) {
          if (modals[i].componentInstance instanceof ErrorModalComponent) {
            modals[i].close();
          }
        }
      }
    });
  }

}
