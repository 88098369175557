import { DomService } from 'src/app/services/dom/dom.service';
import { Injectable } from '@angular/core';

import { DbUtilService } from '../db-util/db-util.service';
import { AuthService } from '../auth/auth.service';
import { StateDataService } from '../state-data/state-data.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    public dbUtilService: DbUtilService,
    public authService: AuthService,
    public domService: DomService,
    public stateDataService: StateDataService
  ) { }

  postPasswordReset(token: string, password: string) {
    return new Promise<any>((resolve, reject) => {

      this.dbUtilService.APIPost('user_access/reset_pass',
        {
          token_uid: token,
          new_password: password
        })
        .then((data) => {
          resolve(data);
        })
        .catch(() => {
          reject();
        });
    });
  }

  getSubTokenInfo(token: string) {
    return new Promise<any>((resolve, reject) => {

      this.dbUtilService.APIGet('user_access/registered', { token_uid: token })
        .then((result) => {
          if (result !== null) {
            const type = (result.token_type ? result.token_type.toLowerCase() : '') + ' ';

            if (result.used) { reject('This ' + type + 'link has already been used.'); }
            else if (result.expired) { reject('This ' + type + 'link has expired.'); }
            else if (result.invalid) {
              reject('There was an issue with this ' + type + 'link.'
                + ' You can request a new link from your company administrator.');
            }
            resolve(result);
          }
          else {
            reject('There was an issue with this link.'
              + ' You can request a new link from your company administrator.');
          }
        })
        .catch((err) => {
          reject();
        });
    });
  }

  postAcceptInvite(token: string, full_name: string, password: string, two_factor_token: string) {
    return new Promise<any>((resolve, reject) => {

      this.dbUtilService.APIPost('user_access/accept/password',
        {
          token_uid: token,
          full_name,
          password,
          two_factor_token
        })
        .then((data) => {

          if (data.access_error_flag) {
            this.domService.showBanner({
              message: 'There was an issue modifying this User\'s access to a Client\'s Account',
              type: 'ERROR'
            });
          }

          this.stateDataService.intialiseLocalStorage(data.user_access_key);
          if (data.karmly_intercom_hash) {
            this.stateDataService.cacheLocalData('karmly_intercom_hash', data.karmly_intercom_hash);
          }
          if (data.droppah_intercom_hash) {
            this.stateDataService.cacheLocalData('droppah_intercom_hash', data.droppah_intercom_hash);
          }
          if (data.intercom_hash) {
            this.stateDataService.cacheLocalData('intercom_hash', data.intercom_hash);
          }

          if (data.session_key) {
            this.authService.session_key = data.session_key;
          }
          resolve(data);

        })
        .catch((err) => {
          if (err) { return reject(err); }
          reject();
        });
    });
  }

  postContinueIntegrationSignup(token: string, email: string, password: string, two_factor_token: string, marketing_additional_data: string) {
    return new Promise<any>((resolve, reject) => {

      this.dbUtilService.APIPost('integration/signup/continue',
        {
          verification_token: token,
          username: email,
          password,
          two_factor_token,
          marketing_additional_data
        })
        .then((data) => {
          if (data.session_key) {
            this.authService.session_key = data.session_key;
            this.stateDataService.intialiseLocalStorage(data.user_access_key);
            if (data.karmly_intercom_hash) {
              this.stateDataService.cacheLocalData('karmly_intercom_hash', data.karmly_intercom_hash);
            }
            if (data.droppah_intercom_hash) {
              this.stateDataService.cacheLocalData('droppah_intercom_hash', data.droppah_intercom_hash);
            }
            if (data.intercom_hash) {
              this.stateDataService.cacheLocalData('intercom_hash', data.intercom_hash);
            }
          }
          resolve(data);

        })
        .catch((err) => {
          if (err) { return reject(err); }
          reject();
        });
    });
  }

  postRegisterPassword(token: string, email: string, full_name: string, password: string, two_factor_token: string) {
    return new Promise<any>((resolve, reject) => {

      this.dbUtilService.APIPost('user_access/register/password',
        {
          token_uid: token,
          registered_email: email,
          full_name,
          password,
          two_factor_token
        })
        .then((data) => {
          if (data.session_key) {
            this.authService.session_key = data.session_key;
            this.stateDataService.intialiseLocalStorage(data.user_access_key);
            if (data.karmly_intercom_hash) {
              this.stateDataService.cacheLocalData('karmly_intercom_hash', data.karmly_intercom_hash);
            }
            if (data.droppah_intercom_hash) {
              this.stateDataService.cacheLocalData('droppah_intercom_hash', data.droppah_intercom_hash);
            }
            if (data.intercom_hash) {
              this.stateDataService.cacheLocalData('intercom_hash', data.intercom_hash);
            }
          }
          resolve(data);

        })
        .catch((err) => {
          if (err) { return reject(err); }
          reject();
        });
    });
  }

  postUpdateUserRegisteredEmail(token: string, password: string, two_factor_token: string) {
    return new Promise<any>((resolve, reject) => {

      this.dbUtilService.APIPost('user_access/email',
        {
          token_uid: token,
          password,
          two_factor_token
        })
        .then((data) => {
          if (data && data.length) {
            data = data[0];
          }
          resolve(data);
        })
        .catch((err) => {
          if (err) { return reject(err); }
          reject();
        });
    });
  }


}
