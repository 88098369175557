import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { StateService, StateDeclaration, UIRouter, UIRouterGlobals, StateRegistry } from '@uirouter/core';

import { AuthService } from '../auth/auth.service';
import { AccountService } from './../account/account.service';
import { SubscriptionService } from '../subscription/subscription.service';
import { UserService } from './../user/user.service';
import { PartnerService } from '../partner/partner.service';
import { ClientService } from '../client/client.service';
import { PartnerStaffService } from './../partner-staff/partner-staff.service';
import { FtAccountService } from './../ft-account/ft-account.service';
import { ProductService } from 'src/app/services/product/product.service';
import { ActiveNavItems } from 'src/app/app.types';
import { cloneDeep } from 'lodash-es';

export type StateName = (
  'app.account.*' |
  'app.account.partnerDash' |
  'app.account.create' |
  'app.subscription.*' |
  'app.subscription.billing' |
  'app.subscription.rebate' |
  'app.client.*' |
  'app.staff.*' |
  'app.partner.*' |
  'app.profile.*'
);
@Injectable({
  providedIn: 'root'
})
export class StateAccessService {

  _servicesInitialised: boolean = false;

  private readonly _UNLOCKED_STATE_DEFAULTS: Record<StateName, boolean> = {
    /////////////////////////////////////
    'app.client.*': true,
    /////////////////////////////////////
    'app.account.*': true,
    'app.account.partnerDash': true,
    'app.account.create': true,
    /////////////////////////////////////
    'app.staff.*': true,
    /////////////////////////////////////
    'app.subscription.*': true,
    'app.subscription.billing': true,
    'app.subscription.rebate': true,
    /////////////////////////////////////
    'app.partner.*': true,
    /////////////////////////////////////
    'app.profile.*': true
  };

  private _STATE_CONFIG: Record<string, StateDeclaration[]>;
  private _unlockedStates: Record<StateName, boolean>;

  private _accessFlags: {
    has_subscription: boolean,
    is_partner: boolean,
    is_partner_owner: boolean,
    is_partner_admin: boolean
  };

  private _activeNavitems: ActiveNavItems;

  private _stateAccessReloadedEvent = new Subject<void>();
  private _subServiceInitialisedEvent = new Subject<void>();

  constructor(
    private stateService: StateService,
    private authService: AuthService,
    private accountService: AccountService,
    private userService: UserService,
    private subscriptionService: SubscriptionService,
    private router: UIRouter,
    private uiRouterGlobals: UIRouterGlobals,
    private stateRegistry: StateRegistry,
    private partnerService: PartnerService,
    private clientService: ClientService,
    private partnerStaffService: PartnerStaffService,
    private ftAccountService: FtAccountService,
    public productService: ProductService
  ) {
    this._unlockedStates = cloneDeep(this._UNLOCKED_STATE_DEFAULTS);
    this.loadStateConfig();
  }

  get activeNavItems(): ActiveNavItems {
    return this._activeNavitems;
  }

  get isPartner(): boolean {
    return this._accessFlags.is_partner;
  }

  get isPartnerOwner(): boolean {
    return this._accessFlags.is_partner_owner;
  }

  getStateAccessReloadedEvent(): Observable<any> {
    return this._stateAccessReloadedEvent.asObservable();
  }

  getSubServiceInitialisedEvent(): Observable<any> {
    return this._subServiceInitialisedEvent.asObservable();
  }

  loadStateConfig() {
    const states = this.stateService.get();

    this._STATE_CONFIG = {
      subscription: [],
      account: [],
      client: [],
      staff: [],
      partner: [],
      profile: []
    };

    for (const state of states) {
      if (state.name.indexOf('app.') === 0) {
        if (state.name.indexOf('app.client') === 0) {
          this._STATE_CONFIG.client.push(state);
        }
        else if (state.name.indexOf('app.account') === 0) {
          this._STATE_CONFIG.account.push(state);
        }
        else if (state.name.indexOf('app.staff') === 0) {
          this._STATE_CONFIG.staff.push(state);
        }
        else if (state.name.indexOf('app.subscription') === 0) {
          this._STATE_CONFIG.subscription.push(state);
        }
        else if (state.name.indexOf('app.partner') === 0) {
          this._STATE_CONFIG.partner.push(state);
        }
        else if (state.name.indexOf('app.profile') === 0) {
          this._STATE_CONFIG.profile.push(state);
        }
      }
    }
  }

  reloadStateAccess(refreshStateName: string = null, refreshStateParams: any = null) {
    this.lockAuthStates();
    const user_access = this.userService.user_access;

    if (!user_access) {
      this.clearServiceData();
      this.authService.logout();
    }
    else {
      this._accessFlags = {
        has_subscription: user_access.subscription_flag,
        is_partner: user_access.partner_flag,
        is_partner_owner: user_access.partner_owner_flag,
        is_partner_admin: user_access.partner_admin_flag
      };

      this._unlockedStates = cloneDeep(this._UNLOCKED_STATE_DEFAULTS);

      this._unlockedStates['app.subscription.rebate'] = false;

      if (!this._accessFlags.has_subscription) {
        this._unlockedStates['app.subscription.*'] = false;
        this._unlockedStates['app.subscription.billing'] = false;
        this._unlockedStates['app.account.create'] = false;
      }

      if (this._accessFlags.is_partner) {
        this._unlockedStates['app.account.partnerDash'] =
          this.partnerService.getAllPartnerAccounts(['EXCLUDE_CANCELLED', 'EXCLUDE_NO_SUBSCRIPTION_ACCESS'], null, true)?.length > 0;

        if (!this._accessFlags.is_partner_owner) {
          this._unlockedStates['app.partner.*'] = false;
          this._unlockedStates['app.subscription.billing'] = false;

          if (!this._accessFlags.is_partner_admin) {
            this._unlockedStates['app.staff.*'] = false;
          }
        }
      }
      else {
        this._unlockedStates['app.client.*'] = false;
        this._unlockedStates['app.staff.*'] = false;
        this._unlockedStates['app.partner.*'] = false;
        this._unlockedStates['app.account.partnerDash'] = false;
      }


      this.unlockStatesInMap(this._STATE_CONFIG, this._unlockedStates, 'app');
      this.reloadActiveNavItems();

      const default_state = this._accessFlags.is_partner ? 'app.client.dash' : 'app.account.dash';
      const default_state_params = this._accessFlags.is_partner ? {} : {
        auto_select_account_flag: true,
        auto_select_account_product_value: this.productService.current_product,
        check_for_expired_trial_account: true
      };
      this.goToStateAfterReload(
        default_state, default_state_params, refreshStateName, refreshStateParams
      );
      this._stateAccessReloadedEvent.next();
    }
  }

  unlockStatesInMap(STATE_CONFIG: any, unlockedStates: any, baseModule: string) {
    for (const module of Object.keys(STATE_CONFIG)) {
      const MODULE_CONFIG = STATE_CONFIG[module];

      for (const state of MODULE_CONFIG) {
        const stateName = state.name;

        if (!this.stateRegistry.get(stateName)) {
          // eg. app.time.* = global module definition
          // eg. app.time.employee = single state definition. Takes precedence over a global module definition

          // Check if a global module definition exists for all states in this module
          if (unlockedStates[baseModule + '.' + module + '.*'] !== undefined) {
            // If a global module defintion does exist, we need to also check if a single state defition exists for this state.
            // If a single state defition does exist, its value will override that of the global module defintion
            if ((unlockedStates[stateName] === undefined && unlockedStates[baseModule + '.' + module + '.*'] === true) ||
              unlockedStates[stateName] === true) {

              this.ensureModuleRegistered(baseModule, module, MODULE_CONFIG);
              if (!this.stateRegistry.get(stateName)) {
                this.stateRegistry.register(state);
              }
            }
          }
          // No global module defition exists so need to rely on the value of the
          // single state defintion for this state
          else {
            if (unlockedStates[stateName] === true) {

              this.ensureModuleRegistered(baseModule, module, MODULE_CONFIG);
              if (!this.stateRegistry.get(stateName)) {
                this.stateRegistry.register(state);
              }
            }
          }
        }
      }
    }
  }

  ensureModuleRegistered(baseModule: string, module: string, MODULE_CONFIG: any) {
    if (!this.stateRegistry.get(baseModule + '.' + module)) {
      for (const state of MODULE_CONFIG) {

        if (state.name === baseModule + '.' + module) {
          this.stateRegistry.register(state);
          return;
        }
      }
    }
  }

  goToStateAfterReload(
    defaultState: string,
    defaultStateParams: any = null,
    refreshStateName: string = null,
    refreshStateParams: any = null
  ) {
    if (refreshStateName && this.router.stateRegistry.get(refreshStateName)) {
      this.stateService.go(refreshStateName, refreshStateParams, { reload: true });
    }
    // Current state no longer registered
    else {
      const current_state = this.uiRouterGlobals.current.name;

      if (current_state === 'splash' || !this.router.stateRegistry.get(current_state)) {
        this.stateService.go(defaultState, defaultStateParams, { reload: true });
      }
    }
  }

  reloadActiveNavItems() {
    const active_nav_items: ActiveNavItems = [[], []];

    if (!!this.stateRegistry.get('app.client')) {
      const states: string[] = [];
      states.push('app.client.dash');

      active_nav_items[0].push({ root_state: 'app.client', states });
    }
    if (!!this.stateRegistry.get('app.account')) {
      const states: string[] = [];
      states.push('app.account.dash');

      if (!!this.stateRegistry.get('app.account.partnerDash')) {
        states.push('app.account.partnerDash');
      }

      active_nav_items[0].push({ root_state: 'app.account', states });
    }
    if (!!this.stateRegistry.get('app.subscription')) {
      const states: string[] = [];
      states.push('app.subscription.dash');

      if (!!this.stateRegistry.get('app.subscription.billing')) {
        states.push('app.subscription.billing');
      }
      if (!!this.stateRegistry.get('app.subscription.invoice')) {
        states.push('app.subscription.invoice');
      }
      if (!!this.stateRegistry.get('app.subscription.rebate')) {
        states.push('app.subscription.rebate');
      }

      active_nav_items[0].push({ root_state: 'app.subscription', states });
    }

    if (!!this.stateRegistry.get('app.staff')) {
      const states: string[] = [];
      states.push('app.staff.dash');

      active_nav_items[0].push({ root_state: 'app.staff', states });
    }
    if (!!this.stateRegistry.get('app.profile')) {
      const states: string[] = [];
      states.push('app.profile.edit');

      active_nav_items[1].push({ root_state: 'app.profile', states });
    }
    if (!!this.stateRegistry.get('app.partner')) {
      const states: string[] = [];
      states.push('app.partner.edit');

      active_nav_items[1].push({ root_state: 'app.partner', states });
    }

    this._activeNavitems = active_nav_items;
  }

  lockAuthStates() {
    if (this.stateRegistry.get('app.profile')) {
      this.stateRegistry.deregister('app.profile');
    }
    if (this.stateRegistry.get('app.account')) {
      this.stateRegistry.deregister('app.account');
    }
    if (this.stateRegistry.get('app.subscription')) {
      this.stateRegistry.deregister('app.subscription');
    }
    if (this.stateRegistry.get('app.client')) {
      this.stateRegistry.deregister('app.client');
    }
    if (this.stateRegistry.get('app.staff')) {
      this.stateRegistry.deregister('app.staff');
    }
    if (this.stateRegistry.get('app.partner')) {
      this.stateRegistry.deregister('app.partner');
    }
  }

  lockApp() {
    this.lockAuthStates();
    const all_states = this.stateRegistry.get();

    for (const state of all_states) {
      if (
        state.name !== '' &&
        state.name !== 'locked' &&
        !!this.stateRegistry.get(state.name)
      ) {
        this.stateRegistry.deregister(state.name);
      }
    }
  }

  ensureServicesInitialised() {
    return new Promise<void>((resolve, reject) => {
      this._servicesInitialised = false;

      if (this.authService.verifyAuth()) {

        this.userService.initialiseService()
          .then(() => {
            //don't wait for subscriptions Service to initialise
            this.ensureSubscriptionServiceInitialised();

            this.accountService.initialiseService()
              .then(() => {
                this.ensurePartnerServicesInitialised()
                  .then(() => {
                    this._servicesInitialised = true;
                    resolve();
                  })
                  .catch((err) => {
                    reject(err);
                  });
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      }
      else {
        reject('AuthService.verifyAuth() failed');
      }
    });
  }

  ensureSubscriptionServiceInitialised() {
    if (this.userService.has_subscription) {
      this.subscriptionService.initialiseService()
        .catch(() => { });
    }
  }

  ensurePartnerServicesInitialised() {
    return new Promise<void>((resolve, reject) => {

      if (this.userService.is_partner) {
        this.ftAccountService.initialiseService()
          .then(() => {
            this.partnerStaffService.initialiseService()
              .then(() => {
                this.clientService.initialiseService()
                  .then(() => {
                    if (this.userService.is_partner_owner) {
                      this.partnerService.initialiseService()
                        .then(() => {
                          resolve();
                        })
                        .catch((err) => {
                          reject(err);
                        });
                    }
                    else {
                      resolve();
                    }
                  })
                  .catch((err) => {
                    reject(err);
                  });
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      }
      else {
        resolve();
      }
    });
  }

  clearServiceData() {
    this.userService.clearServiceData();
    this.accountService.clearServiceData();
    this.subscriptionService.clearServiceData();
    this.partnerService.clearServiceData();
    this.clientService.clearServiceData();
    this.partnerStaffService.clearServiceData();
    this.ftAccountService.clearServiceData();

    this._servicesInitialised = false;

    window.Intercom('shutdown');
  }

}
